.cell-center {
  text-align: center;
}

.cell-center>* {
  margin: 0 auto;
}

.cell-right {
  text-align: right;
}

.cell-right>* {
  margin: 0 0 0 auto;
}

::selection {
  color: white;
  background: rgb(6, 6, 118);
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.borderBottom {
  padding-bottom: 10px;
  border-bottom: 2px solid #c3bfbf;
}

.textCapitalization {
  text-transform: capitalize;
}

/* .textUppercase .MuiInputBase-input-MuiOutlinedInput-input {
  text-transform: uppercase !important;
} */

.textWrap {
  text-wrap: wrap;
}

.error-border .MuiOutlinedInput-notchedOutline {
  border-color: #ff4d4f !important;
}

.error-border .MuiOutlinedInput-notchedOutline:hover {
  border-color: #ff4d4f !important;
}